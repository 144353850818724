import Phaser from 'phaser';
import { loadCharacter } from '../components/gameComponents/character';

export default class PreloadScene extends Phaser.Scene {
  constructor() {
    super('PreloadScene');
  }

  preload() {
    this.load.spritesheet('coinS', './panel/coin.png', { frameWidth: 16, frameHeight: 16});
    this.load.spritesheet('angelL', './characters/angel.png', { frameWidth: 64, frameHeight: 60});
    this.load.spritesheet('crystal', './panel/crystal.png', { frameWidth: 64, frameHeight: 64});

    this.load.image('joystickBase2', './pad/pad2.png');
        this.load.image('panel1', './panel/panel.png');
        this.load.image('smallblue', './panel/sblueB.png');
        this.load.image('smallred', './panel/redB.png');

        this.load.image('panelb', './panel/panelBig.png');

        this.load.image('face', './panel/BOB.png');
        this.load.image('joystickThumb', './pad/handle.png');  

    //this.add.text(this.scale.width / 2, this.scale.height / 2, "Loading...", { fontSize: '32px', fill: '#fff' }).setOrigin(0.5, 0.5);
      loadCharacter(this);

    this.load.image('spinner', './panel/BOB.png');
    this.load.spritesheet('alice', './characters/alice.png', { frameWidth: 64, frameHeight: 51});
    this.load.spritesheet('mist', './characters/mist.png', { frameWidth: 64, frameHeight: 51});

    this.load.spritesheet('fountain', './powerhouse/Fountain.png', { frameWidth: 64, frameHeight: 96});
    this.load.spritesheet('vendor', './characters/vendor.png', { frameWidth: 64, frameHeight: 51});
    this.load.image('chestfull', './characters/fullchest.png');

    this.load.image('garage', './garage/garage.png');
    this.load.image('garageB', './garage/bottom.png');
    this.load.image('table', './garage/table.png');
    this.load.image('texture1', './field/texture1.png');
    this.load.image('texture2', './field/texture2.png');
    this.load.image('texture3', './field/texture3.png');
    this.load.image('texture4', './field/texture4.png');

    this.load.image('leaderb', './field/leaderboard.png');

    this.load.image('wallg', './field/fences/wallg.png');
    this.load.image('street', './field/terrains/street.png');
    this.load.image('treeT', './field/terrains/treetop.png');
    this.load.image('treeB', './field/terrains/treebottom.png');

    this.load.image('indications', './field/fences/indications.png');
    this.load.image('power', './powerhouse/powerhouse.png');
    this.load.image('angel', './powerhouse/angel.png');

      this.load.image('terrain2', './field/terrains/terrain2.png');
      this.load.image('home1', './field/homes/home1.png');
      this.load.image('grass', './field/grass.png');
      this.load.image('nograss', './field/grass.png');
      this.load.image('closegate', './field/fences/closegate.png');

      this.load.image('lateralg', './field/fences/lateralg.png');
      this.load.image('cornergl', './field/fences/cornergl.png');
      this.load.image('close1', './field/fences/close1.png');
      this.load.image('close2', './field/fences/close2.png');

      this.load.image('wallbl', './field/fences/fenceleftb.png');
      this.load.image('cornergr', './field/fences/cornergr.png');
      //const progressText = this.add.text(this.scale.width / 2, this.scale.height / 2 + 50, "0%", { fontSize: '32px', fill: '#fff' }).setOrigin(0.5, 0.5);
      // this.load.on('progress', (value) => {
      //   progressText.setText(`${parseInt(value * 100)}%`);
      // });
      
  }

  create() {
    
    const spinner = this.add.sprite(this.scale.width / 2, this.scale.height / 2 + 50, 'spinner').setOrigin(0.5, 0.5);
    spinner.setScale(0.7);
    this.tweens.add({ targets: spinner, angle: 360, duration: 1000, repeat: -1 });

    // Start the next scene
    this.time.delayedCall(1000, () => {
      this.scene.start('UIScene'); // Start the UIScene here
      this.scene.start('GameScene');
      this.scene.stop('PreloadScene');
    }, 
    [], this);
  }
}
