import Phaser from 'phaser';

class OpenScene extends Phaser.Scene {
  constructor(buyChest,openChest) {
    super({ key: 'OpenScene', active: false });  
    this.buyChest = buyChest;
    this.openChest = openChest;

  }

  preload() {
    this.load.image('board', './panel/board.png');
    this.load.image('exitB', './panel/exit.png');
    this.load.image('bluButton', './panel/bluebutton.png');
  }

  create() {
    this.scene.bringToTop();

    // Use the method to create multiple buttons
    this.createMenuButton(585, 230, "Open Crystals", 0.3);
    this.createMenuButton(585, 300, "Upgrade Companions", 0.35);
    
    //this.createMenuButton(585, 380, "Option 3", 0.4);

    this.board = this.add.image(600, this.scale.height / 2, 'board').setScale(0.65).setInteractive().setDepth(1);
    this.exitB = this.add.image(870, 150, 'exitB').setScale(0.8).setInteractive().setDepth(2);
    this.exitB.on('pointerover', () => this.exitB.setScale(0.81));
    this.exitB.on('pointerout', () => this.exitB.setScale(0.8));
    this.exitB.on('pointerup', () => this.scene.stop('OpenScene'));
  }

  createMenuButton(x, y, label, scaleY) {
    let button = this.add.image(x, y, 'bluButton').setScale(1.1, scaleY).setInteractive().setDepth(2);
    let buttonText = this.add.text(x, y, label, {
      fontSize: '22px',
      fill: 'white',
      fontStyle: 'bold'
    }).setOrigin(0.5).setDepth(3);

    button.on('pointerover', () => button.setScale(1.105, scaleY + 0.05));
    button.on('pointerout', () => button.setScale(1.1, scaleY));
    button.on('pointerup', () => {
          
      this.openSubMenu(label);
    });
  }

  openSubMenu(label) {
    console.log("Open submenu for: " + label);
    // Add logic to handle different submenu opens
    switch(label) {
      case "Open Crystals":
        if (this.buyChest) {
          this.buyChest({
            value: 1000000000,
          });
        }
        break;
      case "Upgrade Companions":
        if (this.openChest) {
          this.openChest();
        }
               break;
      case "Option 3":
        // Open submenu for Option 3
        break;
    }
  }
}

export default OpenScene;
