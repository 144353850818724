import Phaser from 'phaser';
import VirtualJoystick from 'phaser3-rex-plugins/plugins/virtualjoystick.js';
import { createAnimation } from '../components/gameComponents/createAnim';
class UIScene extends Phaser.Scene {
    constructor() {
        super({ key: 'UIScene', active: false });
        this.cursors = null;
        this.isMoving = false;
    }
   

    create() {

        createAnimation(this, 'animate-coin', 'coinS', 0, 5, 7, -1);  
        createAnimation(this, 'animate-crystal', 'crystal', 0, 3, 4, -1);  

        let coins = this.registry.get('coins');
        let Ncrystal = this.registry.get('Ncrystal');

        this.panel = this.add.image(this.scale.width / 2 - 160, 17, 'panel1'); 
        this.panel.setOrigin(0, 0);

        this.panel = this.add.image(this.scale.width / 2 - 20, 17, 'smallred'); 
        this.panel.setOrigin(0, 0).setScale(0.5);


        this.panelB = this.add.image(this.scale.width - 170, this.scale.height- 50, 'panelb'); // -20 is to offset a little to the left for the text
        this.panelB.setScale(0.8);    

          const coin = this.add.sprite((this.scale.width / 2) - 150, 29, 'coin').setOrigin(0, 0);
          coin.anims.play('animate-coin');
          coin.setDepth(5);
          coin.setScale(1.5);    

          const crystal = this.add.sprite((this.scale.width / 2) - 12, 26, 'crystal').setOrigin(0, 0);
          crystal.anims.play('animate-crystal');
          crystal.setDepth(5);
          crystal.setScale(0.5);   
        // Display the number of coins next to the coin image
        this.coinText = this.add.text((this.scale.width / 2) - 125, 30, coins, { 
            fontSize: '20px',
            fill: 'grey'
        }).setOrigin(0, 0);

        this.cursors = this.input.keyboard.createCursorKeys();
        this.joyStick = new VirtualJoystick(this, {
            x: 135,
            y: 465, 
            radius: 60,
            base: this.add.sprite(0, 0, 'joystickBase2').setDepth(100).setScale(0.6),
            thumb: this.add.sprite(0, 0, 'joystickThumb').setDepth(101).setScale(0.6),
        });

        this.joyStick.depth = 100; 
        this.joyStick.thumb.depth = 100;
        this.joyStick.base.depth = 100;
    
        const panelWidth = 330;  // Width of the UI panel
        const panelHeight = 120; // Height of the UI panel
        
        // Align text to the bottom right
        this.scoreText = this.add.text(this.scale.width - panelWidth + 35, this.scale.height - 125, 'Level: 1', {
            fontSize: '25px',
            fill: 'grey'
        }).setOrigin(0, 0);

        this.levelText = this.add.text(this.scale.width - panelWidth + 35, this.scale.height - 95, 'Rewards: 0', {
            fontSize: '18px',
            fill: 'grey'
        }).setOrigin(0, 0);

        this.levelText = this.add.text(this.scale.width - panelWidth + 35, this.scale.height - 75, 'Crystals: 0', {
            fontSize: '18px',
            fill: 'grey'
        }).setOrigin(0, 0);
        this.levelText = this.add.text(this.scale.width - panelWidth + 35, this.scale.height - 55, 'Heroes: 0', {
            fontSize: '18px',
            fill: 'grey'
        }).setOrigin(0, 0);
  
        
        // Create a graphics object and define a fill
        //const panel = this.add.graphics();
        //panel.fillStyle(0x000000, 0.2); // (color, alpha)
        
        // Draw a rectangle in the bottom right
        //panel.fillRect(this.scale.width - panelWidth, this.scale.height - panelHeight, panelWidth, panelHeight);
        
        // Load and display the character face image
        const face = this.add.image(this.scale.width - 40, this.scale.height - 22, 'face');
        
        // Set the origin to the bottom-right corner
        face.setOrigin(1, 1);
        
        // Optional: Scale the image if it's too big
        const faceScale = 0.3; // Adjust as needed
        face.setScale(faceScale);
    }
    

    update() {
        this.handleJoyStickInput();
    }
    
    handleJoyStickInput() {
        const cursorKeys = this.joyStick.createCursorKeys();
        const input = {
            left: cursorKeys.left.isDown,
            right: cursorKeys.right.isDown,
            up: cursorKeys.up.isDown,
            down: cursorKeys.down.isDown
        };
    
        this.events.emit('playerInput', input);
    }

    

    
}

export default UIScene;
