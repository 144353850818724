// Footer.js
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <Box p={3} mt={5} bgcolor="background.paper" textAlign="center">
      <Typography variant="body1">
        BOB Chain © 2023. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
