// dialogues.js
const dialogues = {
    welcome: {
        character: 'BOB',
        message: 'Welcome sweety! My name is BOB. Please follow the road and walk to the next room. You can move using the panel or the arrows on your keyboard.'
    },
    alice: {
        character: 'ALICE',
        message: 'Welcome sweety! I am Alice. Please follow the road and walk to the next room. You can move using the panel or the arrows on your keyboard.'
    },
    vendor: {
        character: 'VENDOR',
        message: 'OOOOOOOOOOOOH BOB AGAIN! How can I help you today?'
    },
    angel: {
        character: 'ANGEL',
        message: 'Are you coming to try your luck?'
    },
    alex: {
        character: 'ALEX',
        message: 'Pick your poison, if you dare.'
    },
    mist: {
        character: 'MIST',
        message: 'Looking for exra crystals? It will cost you.'
    }
};

export default dialogues;
