
export function loadCharacterWar(scene) {
    scene.load.spritesheet('warT', './characters/warriors/warT.png', { frameWidth: 64, frameHeight: 64});
    scene.load.spritesheet('warR', './characters/warriors/warR.png', { frameWidth: 64, frameHeight: 64});
    scene.load.spritesheet('warL', './characters/warriors/warL.png', { frameWidth: 64, frameHeight: 64});
    scene.load.spritesheet('warB', './characters/warriors/warB.png', { frameWidth: 64, frameHeight: 64});

    scene.load.spritesheet('attT', './characters/warriors/attack/attktop.png', { frameWidth: 192, frameHeight: 82});
    scene.load.spritesheet('attR', './characters/warriors/attack/attkright.png', { frameWidth: 192, frameHeight: 82});
    scene.load.spritesheet('attL', './characters/warriors/attack/attkleft.png', { frameWidth: 192, frameHeight: 82});
    scene.load.spritesheet('attB', './characters/warriors/attack/attkbottom.png', { frameWidth: 192, frameHeight: 82});
}

export function createAnimationsWar(scene) {
    if (!scene.anims.exists('walk3-down')) {
    scene.anims.create({
        key: 'walk3-down',
        frames: scene.anims.generateFrameNumbers('warB', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    
    scene.anims.create({
        key: 'walk3-left',
        frames: scene.anims.generateFrameNumbers('warL', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk3-right',
        frames: scene.anims.generateFrameNumbers('warR', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk3-up',
        frames: scene.anims.generateFrameNumbers('warT', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'att-top',
        frames: scene.anims.generateFrameNumbers('attT', { start: 0, end: 5 }),
        frameRate: 10,
        repeat: 0
    });
    scene.anims.create({
        key: 'att-bo',
        frames: scene.anims.generateFrameNumbers('attB', { start: 0, end: 5 }),
        frameRate: 10,
        repeat: 0
    });
    scene.anims.create({
        key: 'att-l',
        frames: scene.anims.generateFrameNumbers('attL', { start: 0, end: 5 }),
        frameRate: 10,
        repeat: 0
    });
    scene.anims.create({
        key: 'att-r',
        frames: scene.anims.generateFrameNumbers('attR', { start: 0, end: 5 }),
        frameRate: 10,
        repeat: 0
    });
}}

export function createCharacterWar(scene, map, startX = 320, startY = 320) {
    const player = scene.physics.add.sprite(startX, startY, 'warR').setOrigin(0, 0);    
    player.setCollideWorldBounds(true);
    player.setScale(1);
    player.setDepth(3);
    scene.cameras.main.setBounds(0, 0, map[0].length * 32, map.length * 32);
    return player;
}
