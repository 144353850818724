import Phaser from 'phaser';
import dialogues from './dialogues/dialogues';

class DialogueScene extends Phaser.Scene {
    constructor() {
        super({ key: 'DialogueScene', active: true });
    }

    preload() {
        this.load.image('profile1', './panel/BOB.png');
        this.load.image('enterButton', './panel/ALICE.png');
    }

    toggleDialogue(tileInfo) {
        let dialogue;
        switch(tileInfo) {
            case 50:
                dialogue = dialogues.alice;
                break;
            case 51:
                
                dialogue = dialogues.vendor;
                break;
            case 54:
                this.time.delayedCall(2000, () => {
                    this.scene.launch('OpenScene'); 
                    this.scene.resume('GameScene');

                    this.panel.setVisible(false);
                    this.profile.setVisible(false);
                    this.dialogueText.setVisible(false);
                }, [], this);
                dialogue = dialogues.angel;
                
                break;

            case 55:
                this.time.delayedCall(2000, () => {
                    this.scene.launch('BuyScene'); 
                    this.scene.resume('GameScene');

                    this.panel.setVisible(false);
                    this.profile.setVisible(false);
                    this.dialogueText.setVisible(false);
                }, [], this);
                dialogue = dialogues.mist;
                break;
            case 60:
                dialogue = dialogues.alex;
                break;
        }
        
        const { character, message } = dialogue;
        this.openDialogue(character, message);
    }
    
    getDialogueFromTile(tileInfo) {
        switch(tileInfo) {
            case 50:
                return dialogues.alice;
            case 51:
                return dialogues.vendor;
            case 54:
                return dialogues.angel;
            case 55:
                return dialogues.mist;
            case 60:
                return dialogues.alex;
        }
    }
    

    create() {
        this.isTextAnimating = false;
        this.canInteract = false; 
        this.tileInfo = null;
        this.enterButton = this.add.image((this.scale.width - 200) - 120, this.scale.height - 170, 'enterButton').setInteractive();
        this.enterButton.setVisible(false);
        this.enterButtonBorder = this.add.graphics();
        this.enterButton.on('pointerdown', () => {
            this.enterButton.setScale(0.13); 
        }, this);

        this.enterButton.on('pointerup', () => {
            this.enterButton.setScale(0.14); 
        }, this);
       
        
        this.tweens.add({
            targets: this.enterButton,
            scale: { from: 0.53, to: 0.55 }, 
            duration: 800,
            yoyo: true,
            repeat: -1
        });
        this.events.on('tileInteraction', (tileInfo) => {
            this.currentTileInfo = tileInfo;
            
            const dialogue = this.getDialogueFromTile(tileInfo);
            const { character } = dialogue;
            
            this.enterButton.setVisible(false); // Hide the button while the texture is loading
        
            if (this.textures.exists(character)) {
                this.enterButton.setTexture(character);
                this.enterButton.setVisible(true); // Show the button after the texture is set
            } else {
                console.warn(`Texture for character ${character} not found.`);
                this.load.image(character, `./panel/${character}.png`);
                this.load.once('complete', () => {
                    this.enterButton.setTexture(character);
                    this.enterButton.setVisible(true); // Show the button after the new texture is loaded
                });
                this.load.start();
            }
        
            this.canInteract = true;
        });
        
        
        this.events.on('notAd', () => {
            this.enterButton.setVisible(false);
            this.canInteract = false;
        });
      
        this.enterButton.on('pointerdown', () => {
            if (this.isTextAnimating) {
                return;
            }
            if (this.panel.visible) {
                this.closeDialogue();
                this.scene.resume('GameScene');
            } else
            if (this.canInteract) {
                this.toggleDialogue(this.currentTileInfo);
            }
        }, this);
        
        this.input.keyboard.on('keydown-ENTER', () => {
            if (this.isTextAnimating) {
                return;
            }
            if (this.panel.visible) {
                this.closeDialogue();
                this.scene.resume('GameScene');

            } else
            if (this.canInteract) {
                //this.enterButton.setVisible(false);
                this.toggleDialogue(this.currentTileInfo);
            }
        }, this);
        
    
        this.panel = this.add.graphics();
        this.panel.fillStyle(0x000000, 0.75); 
        this.panel.fillRect(0, 0, this.scale.width, 150);
        this.panel.setVisible(false); 
        this.profile = this.add.image(20, 20, 'profile1').setOrigin(0, 0).setScale(0.3);
        this.profile.setVisible(false); 

        // Add and hide text
        this.dialogueText = this.add.text(150, 50, 'hi', {
            fontSize: '22px',
            fill: '#fff',
            wordWrap: { width: this.scale.width - 160 }
        }).setOrigin(0, 0);
        this.dialogueText.setVisible(false);
        this.scene.bringToTop();

    }

    openDialogue(character, message) {
        this.scene.pause('GameScene');
        this.loadProfile(character);
        this.panel.setVisible(true);
        this.profile.setVisible(true);
        this.dialogueText.setVisible(true);
        this.updateDialogue(character, message);
       
    }
    loadProfile(character) {
        if (!this.textures.exists(character)) {
            this.load.image(character, `./panel/${character}.png`);
            this.load.once('complete', () => {
                this.profile.setTexture(character);
            });
            this.load.start();
        } else {
            this.profile.setTexture(character);
        }
    }
        

    closeDialogue() {
        this.panel.setVisible(false);
        this.profile.setVisible(false);
        this.dialogueText.setVisible(false);
    }

    updateDialogue(profileKey, text) {
        this.profile.setTexture(profileKey);
        // Capitalize the first letter 
        const name = profileKey.charAt(0).toUpperCase() + profileKey.slice(1);
        this.animateText(`${name}: ${text}`);
    }
    

    animateText(text) {
       
        if(!text || text.length <= 1) {
            console.warn('Text is too short or empty!');
            this.isTextAnimating = false;
            return;
        }
    
        let i = 0;
        this.dialogueText.text = '';
        this.isTextAnimating = true; 
    
        this.time.addEvent({
            repeat: text.length - 1,
            delay: 10, 
            callback: () => {
                this.dialogueText.text += text[i];
                i++;
            },
            callbackScope: this,
        });
    
        const totalDuration = (text.length) * 10;
        this.time.delayedCall(totalDuration, () => {
            this.isTextAnimating = false;
        }, [], this);
    }
    
    
}

export default DialogueScene;
