
export function loadCharacterEnemy(scene) {
    scene.load.spritesheet('skeWT', './characters/enemies/skeWT.png', { frameWidth: 64, frameHeight: 62});
    scene.load.spritesheet('skeWR', './characters/enemies/skeWR.png', { frameWidth: 64, frameHeight: 62});
    scene.load.spritesheet('skeWL', './characters/enemies/skeWL.png', { frameWidth: 64, frameHeight: 62});
    scene.load.spritesheet('skeWB', './characters/enemies/skeWB.png', { frameWidth: 64, frameHeight: 62});
    scene.load.spritesheet('skeDIE', './characters/enemies/skeDEAD.png', { frameWidth: 64, frameHeight: 55});

}

export function createAnimationsEnemy(scene) {
    if (!scene.anims.exists('walk2-down')) {
        scene.anims.create({
            key: 'death-animation',
            frames: scene.anims.generateFrameNumbers('skeDIE', { start: 0, end: 5 }),
            frameRate: 8,
            repeat: 0, 
            hideOnComplete: true  
        });
        
    scene.anims.create({
        key: 'walk2-down',
        frames: scene.anims.generateFrameNumbers('skeWB', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk2-left',
        frames: scene.anims.generateFrameNumbers('skeWL', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk2-right',
        frames: scene.anims.generateFrameNumbers('skeWR', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk2-up',
        frames: scene.anims.generateFrameNumbers('skeWT', { start: 0, end: 8 }),
        frameRate: 10,
        repeat: -1
    });
}}

export function createCharacterEnemy(scene, map, startX = 320, startY = 320) {
    const player = scene.physics.add.sprite(startX, startY, 'skeWR').setOrigin(0, 0);    
    player.setCollideWorldBounds(true);
    player.setScale(1);
    player.setDepth(3);
    scene.cameras.main.setBounds(0, 0, map[0].length * 32, map.length * 32);
    return player;
}
