// useContractMethods.js

import { useAccount } from 'wagmi';
import { useContractWrite } from 'wagmi';
import { useContractReads } from 'wagmi';
import { useBalance } from 'wagmi'
import ABI from './Abi';
import { useNetwork } from 'wagmi'

const CONTRACT_ADDRESS = "0xa361A074AB1D62043CDb81fb15C77F9bB532Ae60";

export function useContractMethods() {

  const { chain, chains } = useNetwork()
  const { address } = useAccount();
  const { data: getUserBalance} = useBalance({
    address: address,
  })
  const ABIread = {
    address: '0xa361A074AB1D62043CDb81fb15C77F9bB532Ae60',
    abi: ABI,
  }
  const {  write: buyChest  } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'buyChest',
  });
  
  const { data: getUser} = useContractReads({
    contracts: [
      {
        ...ABIread,
        functionName: 'getUser',
        args:[address]
      }
    ],
  });
  const chestInfoArgs = getUser && getUser[0] && getUser[0].result && getUser[0].result.chestIds ? [getUser[0].result.chestIds[0]] : [];
  const {  write: openChest  } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'openChest',
  });
  const { data: chestInfo} = useContractReads({
    contracts: [
      {
        ...ABIread,
        functionName: 'itemIdToItems',
        args:chestInfoArgs
      }
    ],
  });
  console.log(chestInfo,"data");

  return {
    buyChest,
    openChest,
    getUserBalance
  };
}
