import Phaser from 'phaser';
import { loadCharacter } from '../components/gameComponents/character';
import { loadCharacterEnemy } from '../components/gameComponents/enemies/enemies';
import { loadCharacterWar } from '../components/gameComponents/war/war';
export default class PreloadWork extends Phaser.Scene {
  constructor() {
    super('PreloadWork');
  }

  preload() {
    loadCharacter(this);
    loadCharacterEnemy(this);
    loadCharacterWar(this);
    this.load.spritesheet('alex', './characters/alex.png', { frameWidth: 128, frameHeight: 82});

    this.load.image('walL', './work/fences/walL.png');
    this.load.image('walR', './work/fences/walR.png');

    this.load.image('wallbr', './field/fences/fenceleftr.png');
    this.load.image('door', './work/fences/door.png');
    this.load.image('corl', './work/fences/cornerleft.png');
    this.load.image('corr', './work/fences/cornerright.png');
    this.load.image('corlb', './work/fences/walLB.png');
    this.load.image('corlr', './work/fences/walLR.png');
    this.load.image('simpleg', './work/fences/simple.png');

    this.load.image('t1', './work/texture1.png');
    this.load.image('t2', './work/texture2.png');
    this.load.image('t3', './work/texture3.png');
    this.load.image('tomb1', './work/tomb/tomb1.png');
    this.load.image('tomb2', './work/tomb/tomb2.png');

    this.load.image('street', './field/terrains/street.png');
    this.load.image('indications2', './work/indications.png');
    this.load.image('indicationsb', './work/indicationsb.png');

    this.load.image('terrain2', './field/terrains/terrain2.png');
    this.load.image('wallr', './field/fences/fencer.png');
    this.load.image('home1', './field/homes/home1.png');
    this.load.image('stones', './work/stones.png');
    this.load.image('land', './work/land.png');

    this.load.image('fencetopr', './field/fences/fencetopl3.png');
    this.load.image('spinner', './panel/BOB.png');
    this.load.image('wall2', './work/fences/gate32.png');
  }

  create() {
    
    const spinner = this.add.sprite(this.scale.width / 2, this.scale.height / 2 + 50, 'spinner').setOrigin(0.5, 0.5);
    spinner.setScale(0.7);
    this.tweens.add({ targets: spinner, angle: 360, duration: 1000, repeat: -1 });
    this.time.delayedCall(1000, () => {
      this.scene.start('WorkScene');
      this.scene.start('UIScene');
      this.scene.stop('PreloadWork');

    }, 
    [], this);
  }
}
