export function handlePlayerMovement(player, cursors, isMoving, scene, isPathClear, onTile,desiredMovement) {
    if (!player ||!player.anims || !cursors || isMoving ) return;

    if (onTile(17) ) {
        scene.scene.start('PreloadWork');
        scene.scene.stop('GameScene');
        scene.scene.stop('UIScene');
        scene.scene.stop('FullScreenToggleScene');

    }
    if (onTile(18)) {
        scene.scene.start('PreloadScene');
        scene.scene.stop('WorkScene');
        scene.scene.stop('UIScene');
        scene.scene.stop('FullScreenToggleScene');
    }
    
    if ((cursors.left.isDown || desiredMovement === 'left') && isPathClear(player.x - 32, player.y)) {
        player.anims.play('walk-left', true);
        movePlayer(player, -32, 0, scene);
        
    }
    else if ((cursors.right.isDown || desiredMovement === 'right') && isPathClear(player.x + 32, player.y)) {
        player.anims.play('walk-right', true);
        movePlayer(player, 32, 0, scene);
    }
    else if ((cursors.up.isDown || desiredMovement === 'up') && isPathClear(player.x, player.y - 32)) {
        player.anims.play('walk-up', true);
        movePlayer(player, 0, -32, scene);
    }
    else if ((cursors.down.isDown || desiredMovement === 'down') && isPathClear(player.x, player.y + 32)) {
        player.anims.play('walk-down', true);
        movePlayer(player, 0, 32, scene);
    }
    else {
        player.anims.stop();
    }
}

function movePlayer(player, deltaX, deltaY, scene) {
    scene.isMoving = true;
    const targetX = Math.round(player.x + deltaX);
    const targetY = Math.round(player.y + deltaY);
    scene.tweens.add({
        targets: player,
        x: targetX,
        y: targetY,
        duration: 100,
        ease: 'Linear',
        onComplete: () => {
            player.x = targetX; 
            player.y = targetY;  
            scene.isMoving = false;
        },
    });
}

