import Phaser from 'phaser';

class FullScreenToggleScene extends Phaser.Scene {
    constructor() {
        super({ key: 'FullScreenToggleScene', active: true });
    }
    preload() {
        this.load.image('fullscreenIcon', './panel/fullscreenIcon.png');
    }
    create() {
        if (isFullScreenSupported()) {
            console.log("Fullscreen API is not supported on this device/browser.");
            const enableFullScreen = () => {
                const canvas = document.querySelector('canvas');
            
                if (!document.fullscreenElement) {  
                    canvas.requestFullscreen().catch(err => {
                        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                    });
                } else {  
                    document.exitFullscreen();
                }
            };
            
            document.addEventListener("fullscreenchange", () => {
                if (document.fullscreenElement) {
                    fullscreenIcon.setVisible(false); // hide icon when fullscreen
                } else {
                    fullscreenIcon.setVisible(true);  // show icon when not fullscreen
                }
            });
            
            const fullscreenIcon = this.add.image(20, 20, 'fullscreenIcon')
                .setOrigin(0, 0)
                .setInteractive()
                .on('pointerup', () => {
                    enableFullScreen();
                });
    
            this.tweens.add({
                targets: fullscreenIcon,
                scale: { from: 0.63, to: 0.65 }, // Adjust these values as per need
                duration: 500,
                yoyo: true,
                repeat: -1 // Infinite repeat
            });
        }    
        }
        
    
    
}

export function isFullScreenSupported() {
    return (
        document.fullscreenEnabled || 
        document.webkitFullscreenEnabled || 
        document.mozFullScreenEnabled || 
        document.msFullscreenEnabled
    );
}


export default FullScreenToggleScene;
