// file: MainMenuScene.js
import Phaser from 'phaser';

class MainMenuScene extends Phaser.Scene {
  constructor(getUserBalance) {
    super({ key: 'MainMenuScene', active: true });  
}
preload() {
    
    this.load.image('play', './panel/play.png');
    this.load.image('back', './panel/background.jpeg');

  }
  create() {
    this.back = this.add.image(this.scale.width / 2, this.scale.height / 2, 'back'); // -20 is to offset a little to the left for the text
    this.play = this.add.image(this.scale.width / 2, this.scale.height / 2, 'play'); // -20 is to offset a little to the left for the text
    this.play.setInteractive();
    this.play.on('pointerover', () => {
        this.play.setScale(1.1);  
      });
      this.play.on('pointerout', () => {
        // Return the button to its original size when the pointer is no longer over it
        this.play.setScale(1);
      });
    this.play.on('pointerup', () => {
      this.scene.start('TestScene');  
    });
  }
}

export default MainMenuScene;
