import React from 'react';
import PhaserGame from './components/PhaserGame';
import Navbar from './components/siteComp/Navbar';
import Footer from './components/siteComp/Footer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import {  bsc, bscTestnet } from 'wagmi/chains'

const projectId = '2c6e349a6798e8a98ca69b8c3a7a4fb3'

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bsc, bscTestnet]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({ wagmiConfig, projectId, chains })

export default function App() {

  return (
      <WagmiConfig config={wagmiConfig}>
    <Router>
      <div className="App">
      <button></button>
        <Navbar />
        <Container>
        <div style={{ position: 'fixed', top: '20px', right: '50px', zIndex: 999 }}>
  <w3m-button />
</div>

          <Box display="flex" position="relative">
          
            <main>
              <Routes>
                <Route path="/play" element={<PhaserGame />} />
                <Route path="/marketplace" element={<div>Hi</div>} />
                
                <Route path="*" element={<PhaserGame />} /> 
              </Routes>
            </main>
            
          </Box>
        </Container>
        <Footer />
      </div>
    </Router>
    </WagmiConfig>
  );
}

