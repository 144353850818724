import Phaser from 'phaser';
import { createAnimation } from '../../components/gameComponents/createAnim';
class BuyScene extends Phaser.Scene {
  constructor(buyChest,openChest) {
    super({ key: 'BuyScene', active: false });  
    this.buyChest = buyChest;
    this.openChest = openChest;
  }

  preload() {
    this.load.image('board', './panel/board.png');
    this.load.image('exitB', './panel/exit.png');
    this.load.image('rightArrow', './panel/rightplus.png');
    this.load.image('leftArrow', './panel/leftplus.png');

    this.load.image('bluButton', './panel/bluebutton.png');
  }

  create() {
    this.scene.bringToTop();
    const middleY = (this.scale.height / 2) - 90; // adjust some_offset_value as needed
    createAnimation(this, 'animate-crystal', 'crystal', 0, 3, 4, -1);  

    this.add.sprite(this.scale.width / 2, middleY, 'crystal').play('animate-crystal').setDepth(3).setScale(1.5);
    
    // Use the method to create multiple buttons
    this.createMenuButton(585, 410, "Buy Crystals", 0.3);
    
    //this.createMenuButton(585, 380, "Option 3", 0.4);

    this.board = this.add.image(600, this.scale.height / 2, 'board').setScale(0.65).setInteractive().setDepth(1);
    this.exitB = this.add.image(870, 150, 'exitB').setScale(0.8).setInteractive().setDepth(2);
    this.exitB.on('pointerover', () => this.exitB.setScale(0.81));
    this.exitB.on('pointerout', () => this.exitB.setScale(0.8));
    this.exitB.on('pointerup', () => this.scene.stop('BuyScene'));

    const middleX = this.scale.width / 2;

    // Position below the crystal
    const posY = middleY + 85; // adjust the offset as necessary

    // Display the left arrow, counter, and right arrow
    this.leftArrow = this.add.image(middleX - 70, posY, 'leftArrow').setInteractive().setDepth(3).setScale(0.6);
    this.counterText = this.add.text(middleX, posY, '0', {
        fontSize: '32px',
        fill: 'white',
        fontStyle: 'bold'
    }).setOrigin(0.5).setDepth(3).setScale(1.1);
    this.rightArrow = this.add.image(middleX + 70, posY, 'rightArrow').setInteractive().setDepth(3).setScale(0.6);

    // Counter value
    this.counterValue = 0;
    // Increment counter when right arrow is clicked
    this.rightArrow.on('pointerup', () => {
        this.counterValue++;
        this.counterText.setText(this.counterValue.toString());
        this.updatePrice();
    });

    // Decrement counter when left arrow is clicked
    this.leftArrow.on('pointerup', () => {
        if (this.counterValue > 0) { // ensure it doesn't go below 0
            this.counterValue--;
            this.counterText.setText(this.counterValue.toString());
            this.updatePrice();
        }
    });

        // Initial price based on counterValue
    this.priceValue = this.counterValue * 0.25;
    this.priceText = this.add.text(middleX, posY + 55, `Price: ${this.priceValue} BNB`, { // added 40 to posY for positioning
        fontSize: '28px',
        fill: 'white',
        fontStyle: 'bold'
    }).setOrigin(0.5).setDepth(3);


  }

  createMenuButton(x, y, label, scaleY) {
    let button = this.add.image(x, y, 'bluButton').setScale(1.1, scaleY).setInteractive().setDepth(2);
    let buttonText = this.add.text(x, y, label, {
      fontSize: '22px',
      fill: 'white',
      fontStyle: 'bold'
    }).setOrigin(0.5).setDepth(3);

    button.on('pointerover', () => button.setScale(1.105, scaleY + 0.05));
    button.on('pointerout', () => button.setScale(1.1, scaleY));
    button.on('pointerup', () => {
          
      this.openSubMenu(label);
    });
  }

  openSubMenu(label) {
    console.log("Open submenu for: " + label);
    // Add logic to handle different submenu opens
    switch(label) {
      case "Buy Crystals":
        if (this.buyChest) {
            this.buyChest({
              args: [this.counterValue], 
              value: this.counterValue * 1000000000, 
            });
          }
        break;
    }
  }
  updatePrice() {
    this.priceValue = this.counterValue * 0.25;
    this.priceText.setText(`Price: ${this.priceValue} BNB`);
}
}

export default BuyScene;
