
export function loadCharacter(scene) {
    scene.load.spritesheet('scout', './characters/walking.png', { frameWidth: 32, frameHeight: 49});
}

export function createAnimations(scene) {
    if (!scene.anims.exists('walk-down')) {
    scene.anims.create({
        key: 'walk-down',
        frames: scene.anims.generateFrameNumbers('scout', { start: 18, end: 23 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk-left',
        frames: scene.anims.generateFrameNumbers('scout', { start: 12, end: 17 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk-right',
        frames: scene.anims.generateFrameNumbers('scout', { start: 0, end: 5 }),
        frameRate: 10,
        repeat: -1
    });
    scene.anims.create({
        key: 'walk-up',
        frames: scene.anims.generateFrameNumbers('scout', { start: 6, end: 11 }),
        frameRate: 10,
        repeat: -1
    });
}}

export function createCharacter(scene, map, startX = 32, startY = 32) {
    const player = scene.physics.add.sprite(startX, startY, 'scout').setOrigin(0, 0);    
    player.setCollideWorldBounds(true);
    player.setScale(0.75);
    player.setDepth(9);
    scene.cameras.main.setBounds(0, 0, map[0].length * 32, map.length * 32);
    scene.cameras.main.startFollow(player);

    return player;
}
