import React, { useState } from 'react';
import { Drawer, Typography, Button, Box, IconButton, Divider } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const navItems = [
  { label: "Play", icon: <PlayCircleOutlineIcon /> },
  { label: "Marketplace", icon: <StorefrontIcon /> },
  { label: "Leaderboard", icon: <LeaderboardIcon /> },
  { label: "Documents", icon: <DescriptionIcon /> },
];

const Navbar = () => {
  const [activeItem, setActiveItem] = useState("Play");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();  // New hook from react-router-dom

  const handleNavigation = (path) => {
    setActiveItem(path);
    navigate(path);
    setDrawerOpen(false);
  };

  // Prevent Enter key from triggering the drawer
  const preventEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <IconButton 
        onClick={() => setDrawerOpen(!isDrawerOpen)}
        sx={{
          position: 'absolute',
          top: '1rem',
          left: '1rem',
          zIndex: 1000,
          backgroundColor: '#028A0F',
          color: 'white',
        }}
      >
        {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      <Drawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        variant="temporary"
        anchor="left"
        PaperProps={{
          sx: {
            width: '250px',
            background: 'linear-gradient(45deg, #028A0F, #00C853)',
          }
        }}
      >
        <Box sx={{ px: 3, py: 2 }}>
          <Typography variant="h6" align="center">
            BOB Chain
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} p={1}>
          {navItems.map((item, index) => (
            <Box 
              key={index}
              my={2}
              p={1}
              bgcolor={activeItem === item.label ? '#00C853' : 'transparent'}
              borderRadius="8px"
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s, background-color 0.3s',
                '&:hover': {
                  transform: 'scale(1.1)',
                }
              }}
              onClick={() => {
                setActiveItem(item.label);
                setDrawerOpen(false);
              }}
            >
              <Button 
                component={Link}
                to={`/${item.label.toLowerCase()}`} 
                startIcon={item.icon}
                sx={{ color: 'white', width: '100%', justifyContent: 'start' }}
                onKeyDown={preventEnterKey} // Prevent Enter key
              >
                {item.label}
              </Button>
            </Box>
          ))}
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
