import workmap from '../components/gameComponents/workMap';
import Phaser from 'phaser';
import {  createCharacter  } from '../components/gameComponents/character';
import { handlePlayerMovement } from '../components/gameComponents/playerMovement';
import {  createCharacterEnemy} from '../components/gameComponents/enemies/enemies';
import { enemyMove } from '../components/gameComponents/enemies/enemyMovement';
import { createAnimationsEnemy } from '../components/gameComponents/enemies/enemies';
import { createAnimation } from '../components/gameComponents/createAnim';
import { createAnimations } from '../components/gameComponents/character';
import { createAnimationsWar } from '../components/gameComponents/war/war';
import { createCharacterWar } from '../components/gameComponents/war/war';
import { warMove } from '../components/gameComponents/war/warMovement';

class WorkScene extends Phaser.Scene {
    constructor() {
      super('WorkScene');
      this.player = null;
      this.isMoving = false;
      this.war = null;
      this.enemies = null; 
      this.currentTarget = null;
      this.isWarMoving = false;
      
    }
  
    
    handlePlayerInput(input) {
      if (input.left) {
          this.desiredMovement = 'left';
      } else if (input.right) {
          this.desiredMovement = 'right';
      } else if (input.up) {
          this.desiredMovement = 'up';
      } else if (input.down) {
          this.desiredMovement = 'down';
      } else {
          this.desiredMovement = null;
      }
    }

    
    create() {
      this.cursors = this.input.keyboard.createCursorKeys(); // change from `cursors = this.input.keyboard.createCursorKeys();`
      this.isMoving = false;
      this.isWarEngaged = false;
      this.physics.world.setBounds(0, 0, workmap[0].length * 32, workmap.length * 32);
      this.player = createCharacter(this, workmap, 32 * 2, 32* 11);
      createAnimationsEnemy(this);
      createAnimation(this, 'animate-alex', 'alex', 0, 2, 4, -1);  

        createAnimationsWar(this);
        //createAnimation(this, 'animate-door', 'door', 0, 13, 10, -1);      
        createAnimations(this);
        this.scene.get('UIScene').events.on('playerInput', this.handlePlayerInput, this);
          this.war = createCharacterWar(this, workmap, 300, 300);
      this.war.isAnimating = false;
      
      this.enemies = this.physics.add.group();
      const numOfEnemies = 5;
      
      const enemyXMin = 300;
      const enemyXMax = 700;
      const enemyYMin = 150;
      const enemyYMax = 600;
      
      for(let i = 0; i < numOfEnemies; i++) {
          const enemyX = Math.random() * (enemyXMax - enemyXMin) + enemyXMin;
          const enemyY = Math.random() * (enemyYMax - enemyYMin) + enemyYMin;
          
          const enemy = createCharacterEnemy(this, workmap, enemyX, enemyY);
          this.enemies.add(enemy);
      }
      
      this.enemies.getChildren().forEach((enemy) => {
        if (enemy && enemy.active) {
          enemyMove(this, enemy, workmap);}
      });
      
      for(let y = 0; y < workmap.length; y++){
        for(let x = 0; x < workmap[y].length; x++){
          if(workmap[y][x] === 0){
            this.add.image(x * 32, y * 32, 'stones').setOrigin(0, 0).setDepth(0);
           }
           if(workmap[y][x] === 97){
            this.add.image(x * 32, y * 32, 'stones').setOrigin(0, 0).setDepth(0);
           }
          if(workmap[y][x] === 1){
            this.add.image(x * 32, (y * 32), 'land').setOrigin(0, 0).setDepth(0)
            this.add.image(x * 32, (y * 32) - 32, 'wall2').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 2){
            this.add.image(x * 32, (y * 32), 'land').setOrigin(0, 0).setDepth(1);
          }
          if(workmap[y][x] === 10){
            this.add.image(x * 32, (y * 32) - 32, 'wall2').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 10.1){
            this.add.image((x * 32) - 15, (y * 32) - 26, 'corl').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 10.2){
            this.add.image((x * 32) - 10, (y * 32) - 26, 'corr').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 10.3){
            this.add.image((x * 32) - 15, (y * 32) - 20, 'corlb').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 10.4){
            this.add.image((x * 32) - 10, (y * 32) - 20, 'corlr').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 11){
            // subtracting 32 from the y position to adjust for 64px height
            this.add.image(x * 32, (y * 32) - 32, 'wall2').setOrigin(0, 0).setDepth(5);
          }
          if(workmap[y][x] === 12){
            this.add.image(x * 32, (y * 32), 'stones').setOrigin(0, 0).setDepth(1)
            this.add.image((x * 32) - 18, y * 32, 'walL').setOrigin(0, 0).setDepth(2);
          }
        
          if(workmap[y][x] === 14){
            this.add.image((x * 32) , y * 32, 'walR').setOrigin(0, 0).setDepth(3);
          }
          if(workmap[y][x] === 15){
            this.add.image(x * 32, y * 32, 'fencetopr').setOrigin(0, 0).setDepth(0);
          }
          if(workmap[y][x] === 16){
            this.add.image(x * 32, y * 32, 'street').setOrigin(0, 0).setDepth(1);
          }
          if(workmap[y][x] === 18){
            this.add.image(x * 32, (y * 32), 'stones').setOrigin(0, 0).setDepth(1)
            this.add.image(x * 32 - 5, (y * 32) - 15, 'indicationsb').setOrigin(0, 0).setDepth(2)

            const indications2 = this.add.image((x * 32), (y * 32) - 30, 'indications2').setOrigin(0, 0).setDepth(2.1);
            this.tweens.add({
              targets: indications2,
              scale: { from: 1, to: 1.08 }, // Adjust these values as per need
              duration: 500,
              yoyo: true,
              repeat: -1 // Infinite repeat
          });
          }
          if(workmap[y][x] === 80){
            this.add.image(x * 32, y * 32, 'stones').setOrigin(0, 0).setDepth(0);
            this.add.image((x * 32) - 32, (y * 32) - 50, 't1').setOrigin(0, 0).setDepth(1);
          }
          if(workmap[y][x] === 81){
            this.add.image(x * 32, y * 32, 'stones').setOrigin(0, 0).setDepth(0);
            this.add.image((x * 32), (y * 32) - 322, 't2').setOrigin(0, 0).setDepth(1);
          }
          if(workmap[y][x] === 82){
            this.add.image(x * 32, (y * 32), 'land').setOrigin(0, 0).setDepth(1);
            this.add.image((x * 32) - 51, (y * 32) - 416, 't3').setOrigin(0, 0).setDepth(1.1);
          }
          if(workmap[y][x] === 70){
            this.add.image(x * 32, (y * 32), 'land').setOrigin(0, 0).setDepth(1);
            this.add.image((x * 32) + 40, (y * 32) - 80, 'tomb1').setOrigin(0, 0).setDepth(1.2);
          }
          if(workmap[y][x] === 71){
            this.add.image(x * 32, (y * 32), 'land').setOrigin(0, 0).setDepth(1);
            this.add.image((x * 32) + 0, (y * 32) - 25, 'tomb2').setOrigin(0, 0).setDepth(1.2);
          }
          if(workmap[y][x] === 30){
            this.add.image((x * 32) - 70, (y * 32) - 22, 'terrain2').setOrigin(0, 0).setDepth(2).setScale(1.3);;
  
            this.add.image((x * 32) - 32, (y * 32) - 42, 'home1').setOrigin(0, 0).setDepth(2).setScale(1.3);;
          }
          if(workmap[y][x] === 98){
            this.add.image((x * 32), (y * 32), 'stones').setOrigin(0, 0).setDepth(0);
          }
         
        if(workmap[y][x] === 51){
            this.add.image((x * 32), (y * 32)- 32, 'simpleg').setOrigin(0, 0).setDepth(1);
            this.add.image((x * 32) + 32, (y * 32)- 32, 'simpleg').setOrigin(0, 0).setDepth(1);

            this.add.image((x * 32) - 5, (y * 32) - 80, 'door').setOrigin(0, 0).setDepth(9.1);
            
        }
        if(workmap[y][x] === 60){
          this.add.image((x * 32), (y * 32)- 0, 'stones').setOrigin(0, 0).setDepth(1);
        
          const frank = this.add.sprite((x * 32) - 35, (y * 32) - 35, 'alex').setOrigin(0, 0);
          frank.anims.play('animate-alex');
          frank.setDepth(7);
          frank.setScale(1);    
        }
        }
      }
      
  }

  isPlayerOnTile(tileNumber) {
    const workmapX = Math.floor(this.player.x / 32);
    const workmapY = Math.floor(this.player.y / 32);
    return workmap[workmapY] && workmap[workmapY][workmapX] === tileNumber;
  }

  update() {
    handlePlayerMovement(this.player, this.cursors, this.isMoving, this, this.isPathClear.bind(this), this.isPlayerOnTile.bind(this), this.desiredMovement);  
    let closestEnemy = null;
    let closestDistance = Infinity;
    
    this.enemies.getChildren().forEach((enemy) => {
        const distance = Phaser.Math.Distance.Between(this.war.x, this.war.y, enemy.x, enemy.y);
        if (distance < closestDistance) {
            closestEnemy = enemy;
            closestDistance = distance;
        }
    });

    if (closestDistance === Infinity){
      this.war.setFrame(0);
      return;
    }
    
    if (!this.isWarMoving && !this.currentTarget) {
        this.currentTarget = closestEnemy;
    }
    if (!this.war.isAnimating) {
      warMove(this.war, this, closestEnemy, closestDistance);
  }
  if (this.isPlayerAdjacentToTile(60)) {
    this.scene.get('DialogueScene').events.emit('tileInteraction', 60);
    console.log("yes")
  } else {
    this.scene.get('DialogueScene').events.emit('notAd');
  }
}

    spawnNewEnemy() {
      const enemyXMin = 150;
      const enemyXMax = 700;
      const enemyYMin = 100;
      const enemyYMax = 500;

      const enemyX = Math.random() * (enemyXMax - enemyXMin) + enemyXMin;
      const enemyY = Math.random() * (enemyYMax - enemyYMin) + enemyYMin;

      const enemy = createCharacterEnemy(this, workmap, enemyX, enemyY);
      this.enemies.add(enemy);
      enemyMove(this, enemy, workmap);
    }

  
    movePlayer(deltaX, deltaY) {
      this.isMoving = true;
      this.tweens.add({
        targets: this.player,
        x: this.player.x + deltaX,
        y: this.player.y + deltaY,
        duration: 100,
        ease: 'Linear',
        onComplete: () => {
          this.isMoving = false;
        },
      });
    }
    isPlayerAdjacentToTile(tileNumber) {
      const mapX = Math.floor(this.player.x / 32);
      const mapY = Math.floor(this.player.y / 32);
  
      const adjacentTiles = [
          workmap[mapY - 1] && workmap[mapY - 1][mapX],     // Above
          workmap[mapY] && workmap[mapY][mapX - 1],         // Left
          workmap[mapY] && workmap[mapY][mapX + 1]          // Right
      ];
  
      return adjacentTiles.includes(tileNumber);
  }
    isPathClear(x, y) {
      const workmapX = Math.floor(x / 32);
      const workmapY = Math.floor(y / 32);
      return workmap[workmapY] && ((workmap[workmapY][workmapX] === 0 || workmap[workmapY][workmapX] === 2|| workmap[workmapY][workmapX] === 18) && workmap[workmapY][workmapX] !== 98 );
    }

    
  
  }

  export default WorkScene;