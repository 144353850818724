const enemyMove = (scene, enemy, workmap) => {
    if (enemy && enemy.active) {
        
        enemy.timedEvent = scene.time.addEvent({        
            delay: 2000,
        callback: function () {
            if (!enemy || !enemy.active) return;  // Ensure enemy exists & is active
            let direction = Math.floor(Math.random() * 4);
            
            let nextX = Math.floor(enemy.x / 32);
            let nextY = Math.floor(enemy.y / 32);
    
            switch (direction) {
                case 0:
                    nextY -= 4;
                    break;
                case 1:
                    nextX += 3;
                    break;
                case 2:
                    nextY += 4;
                    break;
                case 3:
                    nextX -= 3;
                    break;
            }
    
            if (workmap[nextY] && workmap[nextY][nextX] === 2) {
                switch (direction) {
                    case 0:
                        enemy.setVelocityY(-100);
                        enemy.play('walk2-up', true);
                        break;
                    case 1:
                        enemy.setVelocityX(100);
                        enemy.play('walk2-right', true);
                        break;
                    case 2:
                        enemy.setVelocityY(100);
                        enemy.play('walk2-down', true);
                        break;
                    case 3:
                        enemy.setVelocityX(-100);
                        enemy.play('walk2-left', true);
                        break;
                }
    
                scene.time.delayedCall(1000, function () {
                    if (!enemy || !enemy.active) return; 
                    enemy.setVelocity(0);
                }, [], this);
            }
        },
        callbackScope: scene,
        loop: true
    });
};}

export { enemyMove };
