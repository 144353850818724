

const warMove = (war, scene, closestEnemy, closestDistance) => {
    if (scene.isWarEngaged) return;  // If War is engaged with an enemy, don't do anything.
    if (closestEnemy && closestDistance <= 50)  {
        if (!closestEnemy.isDying) {
            scene.isWarEngaged = true;  // Set flag to show War is engaged
            const originalOriginX = war.originX;
            const originalOriginY = war.originY;
    
            closestEnemy.isDying = true;
            closestEnemy.anims.stop();
            closestEnemy.play('death-animation', true);
            war.body.enable = false;
            closestEnemy.body.enable = false;
    
            const dx = closestEnemy.x - war.x;
            const dy = closestEnemy.y - war.y;
    
            if (Math.abs(dx) > Math.abs(dy)) {
                war.setOrigin(originalOriginX + 19 / war.width, originalOriginY + 10 / war.height); // Adjust both X and Y origins for horizontal attack animations
                war.play(dx > 0 ? 'att-r' : 'att-l', true);
            } else {
                war.setOrigin(originalOriginX + 19 / war.width, originalOriginY + 10 / war.height); // Adjust both X and Y origins for vertical attack animations
                war.play(dy > 0 ? 'att-bo' : 'att-top', true);
            } 
    
            war.once('animationcomplete', () => {
                war.setOrigin(originalOriginX, originalOriginY); // Reset to original origins after animation completes
                closestEnemy.destroy();
                scene.spawnNewEnemy();
                war.setVelocity(0); 
                war.body.enable = true;
                scene.isWarEngaged = false;  // Set flag to show War is engaged
            });
        }
    }
    
    else if (closestEnemy) {
        const dx = closestEnemy.x - war.x;
        const dy = closestEnemy.y - war.y;

        const magnitude = Math.sqrt(dx * dx + dy * dy);
        const nx = dx / magnitude;
        const ny = dy / magnitude;

        war.setVelocityX(nx * 200);
        war.setVelocityY(ny * 200);
                
        if (Math.abs(dx) > Math.abs(dy)) {
            war.play(dx > 0 ? 'walk3-right' : 'walk3-left', true);
        } else {
            war.play(dy > 0 ? 'walk3-down' : 'walk3-up', true);
        } 
    }

};

export { warMove };
