import React, { useEffect } from 'react';
import Phaser from 'phaser';
import GameScene from '../Class/GameScene';
import WorkScene from '../Class/WorkScene';
import UIScene from '../Class/UIScene';
import DialogueScene from '../Class/DialogueScene';
import PreloadScene from '../Preloads/PreloadScene';
import PreloadWork from '../Preloads/PreloadWork';
import FullScreenToggleScene from '../Class/FullScreenToggleScene';
import MainMenuScene from '../Class/MainMenuScene';
import TestScene from '../Class/TestMenu';
import OpenScene from '../Class/Panels/open';
import BuyScene from '../Class/Panels/buychest';
import { isFullScreenSupported } from '../Class/FullScreenToggleScene';
import { useContractMethods } from './web3/Methods';
const PhaserGame = () => {
  const { buyChest , getUserBalance , openChest} = useContractMethods(); 

  useEffect(() => {
    const initializeGame = () => {
      console.log("init")
      const config = {
        type: Phaser.AUTO,
        scale: {
          parent: 'phaser-game',
          width: 1200,
          height: 600,
          mode: isFullScreenSupported() || window.innerWidth <= window.innerHeight ? Phaser.Scale.NONE : Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
        },
        physics: {
          default: 'arcade',
        },
        scene: [MainMenuScene, new TestScene(getUserBalance), PreloadScene, PreloadWork, WorkScene, GameScene, UIScene, DialogueScene, FullScreenToggleScene,new BuyScene(buyChest,openChest), new OpenScene(buyChest,openChest)],
      };
      window.gameInstance = new Phaser.Game(config);
    };

     const checkOrientation = () => {
      const orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
      if (orientation === 'portrait') {
          document.getElementById('rotate-device').style.display = 'flex';
      } else {
          document.getElementById('rotate-device').style.display = 'none';
      }
    };
  
  

    window.addEventListener('resize', checkOrientation);
    checkOrientation();

    if (!window.gameInstance) {
      initializeGame();
    }
    return () => {
      window.removeEventListener('resize', checkOrientation);
      if (window.gameInstance) {
        window.gameInstance.destroy(true);
        window.gameInstance = null;
      }
    };
    

  }, []);
  

  return (
    
    <div id="game-container">
      <div id="phaser-game" style={{ marginTop: '15%' , marginBottom: '15%'}}>      
          <div id="rotate-device">Please rotate your device</div>
      </div>
    </div>
  );

};

export default React.memo(PhaserGame);
