
const createAnimation = (scene, key, spriteSheet, startFrame, endFrame, frameRate, repeat) => {
    scene.anims.create({
        key: key,
        frames: scene.anims.generateFrameNumbers(spriteSheet, { start: startFrame, end: endFrame }),
        frameRate: frameRate,
        repeat: repeat
    });
}

export { createAnimation };
