import map from '../components/gameComponents/gameMap';
import Phaser from 'phaser';
import { createAnimations, createCharacter  } from '../components/gameComponents/character';
import { createAnimation } from '../components/gameComponents/createAnim';
import { handlePlayerMovement } from '../components/gameComponents/playerMovement';
import EasyStar from 'easystarjs';

class GameScene extends Phaser.Scene {
    constructor() {
      super('GameScene');
      this.player = null;

      this.isMoving = false;
      this.easystar = new EasyStar.js();
    }
    
    handlePlayerInput(input) {
      if (input.left) {
          this.desiredMovement = 'left';
      } else if (input.right) {
          this.desiredMovement = 'right';
      } else if (input.up) {
          this.desiredMovement = 'up';
      } else if (input.down) {
          this.desiredMovement = 'down';
      } else {
          this.desiredMovement = null;
      }
    }

  
    create() {
      this.physics.world.setBounds(0, 0, map[0].length * 32, 23 * 32);
      this.cursors = this.input.keyboard.createCursorKeys();
      this.isMoving = false;
      
      this.scene.get('UIScene').events.on('playerInput', this.handlePlayerInput, this);
      createAnimation(this, 'animate-rvendor', 'vendor', 0, 5, 10, -1);  
      createAnimation(this, 'animate-angel', 'angelL', 0, 5, 10, -1);  
      createAnimation(this, 'animate-mist', 'mist', 0, 1, 2, -1);  

      createAnimation(this, 'animate-fountain', 'fountain', 0, 5, 10, -1);   
 
      createAnimation(this, 'animate-alice', 'alice', 0, 2, 4, -1);      
      createAnimations(this);
      

      for(let y = 0; y < map.length; y++){
        for(let x = 0; x < map[y].length; x++){
          if(map[y][x] === 0){
            this.add.image(x * 32, y * 32, 'grass').setOrigin(0, 0).setDepth(0);
                    }
          if(map[y][x] === 1){
            this.add.image(x * 32, (y * 32) - 32, 'wallg').setOrigin(0, 0).setDepth(8);
          }
          if(map[y][x] === 1.1){
            this.add.image(x * 32, (y * 32) - 32, 'wallg').setOrigin(0, 0).setDepth(10);
          }
          if(map[y][x] === 10){
            this.add.image((x * 32) - 3, y * 32 , 'lateralg').setOrigin(0, 0).setDepth(4);
          }
          if(map[y][x] === 11){
            // subtracting 32 from the y position to adjust for 64px height
            this.add.image((x * 32) + 3, y * 32, 'lateralg').setOrigin(0, 0).setDepth(4);
          }
          if(map[y][x] === 12){
            this.add.image(x * 32, y * 32, 'cornergl').setOrigin(0, 0).setDepth(0);
          }
          if(map[y][x] === 13){
            this.add.image((x * 32) + 3, y * 32, 'cornergl').setOrigin(0, 0).setDepth(5);
          }
          if(map[y][x] === 14){
            this.add.image((x * 32) - 3,  y * 32, 'cornergr').setOrigin(0, 0).setDepth(5);
          }
          if(map[y][x] === 15){
            this.add.image((x * 32) - 3,  y * 32, 'cornergr').setOrigin(0, 0).setDepth(5);
          }
          if(map[y][x] === 16){
            this.add.image(x * 32, y * 32, 'street').setOrigin(0, 0).setDepth(1);
          }
          if(map[y][x] === 18){
            this.add.image((x * 32) - 3, (y * 32) - 10, 'close1').setOrigin(0, 0).setDepth(1);
          }
          if(map[y][x] === 22){
            this.add.image((x * 32) - 3, (y * 32) - 10, 'close2').setOrigin(0, 0).setDepth(2);
          }
          if(map[y][x] === 31){
            this.add.image(x * 32, y * 32, 'grass').setOrigin(0, 0).setDepth(0);
            this.add.image((x * 32) + 32, y * 32, 'grass').setOrigin(0, 0).setDepth(0);

            this.add.image((x * 32) - 32, (y * 32) - 32, 'closegate').setOrigin(0, 0).setDepth(12);
          }
          if(map[y][x] === 17){
            const indications = this.add.image((x * 32) - 20, (y * 32) - 55, 'indications').setOrigin(0, 0).setDepth(2);
            this.tweens.add({
              targets: indications,
              scale: { from: 1, to: 1.08 }, // Adjust these values as per need
              duration: 500,
              yoyo: true,
              repeat: -1 // Infinite repeat
          });
          }
          if(map[y][x] === 19){
            this.add.image((x * 32) - 100, (y * 32) - 205, 'garage').setOrigin(0, 0).setDepth(8);
            
          }
          if(map[y][x] === 20){
            this.add.image((x * 32) - 155, (y * 32) - 101, 'garageB').setOrigin(0, 0).setDepth(8);
          }
          if(map[y][x] === 21){
            this.add.image((x * 32) - 32, (y * 32), 'table').setOrigin(0, 0).setDepth(8.9);
          }
          if(map[y][x] === 30){
            this.add.image((x * 32) - 70, (y * 32) - 22, 'terrain2').setOrigin(0, 0).setDepth(2).setScale(1.3);;
  
            this.add.image((x * 32) - 32, (y * 32) - 42, 'home1').setOrigin(0, 0).setDepth(4).setScale(1.3);;
          }
          if(map[y][x] === 98){
            this.add.image((x * 32), (y * 32), 'nograss').setOrigin(0, 0).setDepth(0);
          }
          
        if(map[y][x] === 23){
          this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0);
  
          const vendor = this.add.sprite((x * 32) - 45, (y * 32) - 20, 'vendor').setOrigin(0, 0);
          vendor.anims.play('animate-rvendor');
          vendor.setDepth(8.8);
          vendor.setScale(0.9);    
      }
        if(map[y][x] === 50){
          this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);
  
          const alice = this.add.sprite((x * 32), (y * 32) - 20, 'alice').setOrigin(0, 0);
          alice.anims.play('animate-alice');
          alice.setDepth(5);
          alice.setScale(0.8);    
      }
      if(map[y][x] === 51){
        this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);
    }
    
    if(map[y][x] === 52){
      this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

      this.add.image((x * 32), (y * 32)- 0, 'power').setOrigin(0, 0).setDepth(1).setScale(1.2);
  }
  
  if(map[y][x] === 53){
    this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(0);

    this.add.image((x * 32) - 52, (y * 32)- 250, 'angel').setOrigin(0, 0).setDepth(12).setScale(0.5);
}
if(map[y][x] === 54){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  const angel = this.add.sprite((x * 32), (y * 32) - 20, 'angelL').setOrigin(0, 0);
  angel.anims.play('animate-angel');
  angel.setDepth(5);
  angel.setScale(0.8);    
}
if(map[y][x] === 55){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  const mist = this.add.sprite((x * 32) - 20, (y * 32) - 20, 'mist').setOrigin(0, 0);
  mist.anims.play('animate-mist');
  mist.setDepth(9.1);
  mist.setScale(0.9);    
}
if(map[y][x] === 56){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  this.add.image((x * 32)- 100, (y * 32) + 50, 'chestfull').setOrigin(0, 0).setDepth(10).setScale(1.2);
}
if(map[y][x] === 57){
  //this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  this.add.image((x * 32)- 430, (y * 32) - 100, 'treeT').setOrigin(0, 0).setDepth(8.1).setScale(1.2);
}
if(map[y][x] === 58){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  this.add.image((x * 32)- 170, (y * 32) - 170, 'treeB').setOrigin(0, 0).setDepth(9.1).setScale(1.2);
}
if(map[y][x] === 59){
  //this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);
  this.add.image((x * 32) - 50, (y * 32) - 30, 'leaderb').setOrigin(0, 0).setDepth(3).setScale(1.2);
}
if(map[y][x] === 80){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);

  this.add.image((x * 32) - 485, (y * 32) - 137, 'texture1').setOrigin(0, 0).setDepth(3);
}
if(map[y][x] === 81){
  this.add.image((x * 32) - 465, (y * 32) - 137, 'texture2').setOrigin(0, 0).setDepth(9.1);
}
if(map[y][x] === 82){
  this.add.image((x * 32) - 600, (y * 32) - 200, 'texture3').setOrigin(0, 0).setDepth(2);
}
if(map[y][x] === 83){
  this.add.image((x * 32), (y * 32)- 0, 'nograss').setOrigin(0, 0).setDepth(1);
  this.add.image((x * 32) - 134, (y * 32) - 128, 'texture4').setOrigin(0, 0).setDepth(2);
}
        }
      }
  
      this.player = createCharacter(this, map, 64 * 17, 32* 11);
      
  }


  isPlayerOnTile(tileNumber) {
    const mapX = Math.floor(this.player.x / 32);
    const mapY = Math.floor(this.player.y / 32);
    return map[mapY] && map[mapY][mapX] === tileNumber;
}

  update() {
    handlePlayerMovement(this.player, this.cursors, this.isMoving, this, this.isPathClear.bind(this), this.isPlayerOnTile.bind(this), this.desiredMovement);  
    if (this.isPlayerOnTile(50)) {
      this.scene.get('DialogueScene').events.emit('tileInteraction', 50);
    } else if (this.isPlayerOnTile(51)) {
      this.scene.get('DialogueScene').events.emit('tileInteraction', 51);
    } else if (this.isPlayerAdjacentToTile(54)) {
      this.scene.get('DialogueScene').events.emit('tileInteraction', 54);
    } else if (this.isPlayerAdjacentToTile(55) || this.isPlayerAdjacentToTile(80)) {
      this.scene.get('DialogueScene').events.emit('tileInteraction', 55);
    }else {
      this.scene.get('DialogueScene').events.emit('notAd');
    }
  }
    isPathClear(x, y) {
      const mapX = Math.floor(x / 32);
      const mapY = Math.floor(y / 32);
      return map[mapY] && ((map[mapY][mapX] === 0 || map[mapY][mapX] === 16|| map[mapY][mapX] === 17 || map[mapY][mapX] ===  50|| map[mapY][mapX] ===  51) && map[mapY][mapX] !== 98 );
    }


    isPlayerAdjacentToTile(tileNumber) {
      const mapX = Math.floor(this.player.x / 32);
      const mapY = Math.floor(this.player.y / 32);
  
      const adjacentTiles = [
          map[mapY - 1] && map[mapY - 1][mapX],     // Above
          map[mapY] && map[mapY][mapX - 1],         // Left
          map[mapY] && map[mapY][mapX + 1]          // Right
      ];
  
      return adjacentTiles.includes(tileNumber);
  }
  
  
  }

  

  export default GameScene;