import Phaser from 'phaser';

class TestScene extends Phaser.Scene {
  constructor(getUserBalance) {
    super({ key: 'TestScene', active: false });  
    this.getUserBalance = getUserBalance;

  }

  preload() {
    this.load.image('back', './panel/background.jpeg');
    this.load.image('test', './panel/test.png');
    this.load.image('real', './panel/real.png');
  }

  create() {
    this.back = this.add.image(this.scale.width / 2, this.scale.height / 2, 'back');
    this.test = this.add.image(450, this.scale.height / 2, 'test').setScale(0.65).setInteractive();
    
    // Create the "real" button
    this.real = this.add.image(0, 0, 'real').setScale(0.65).setInteractive().setTint('0x333333');
    
    // Create the "UNAVAILABLE" text
    const unavailableText = this.add.text(0, 0, 'UNAVAILABLE', {
        fontSize: '24px',
        fill: '#ff0000',
        align: 'center',
        backgroundColor: '#ffffff'
      }).setOrigin(0.5, 0.5);

    // Add the "real" button and the "UNAVAILABLE" text to a container
    this.realContainer = this.add.container(800, this.scale.height / 2, [this.real, unavailableText]);

    // Event handlers for the "real" button
    this.real.on('pointerover', () => {
      this.realContainer.setScale(1.03); // slightly larger scale
    });
    
    this.real.on('pointerout', () => {
      this.realContainer.setScale(1); // reset scale
    });

    this.real.on('pointerup', () => {
      if (this.write) {
        this.write();
      }
      });

    // Event handlers for the "test" button
    this.test.on('pointerover', () => {
      this.test.setScale(0.67);  
    });

    this.test.on('pointerout', () => {
      this.test.setScale(0.65);
    });

    this.test.on('pointerup', () => {
      
    this.registry.set('coins', Number(this.getUserBalance.formatted).toFixed(2) );  // Set initial coin count to 0

      this.scene.start('PreloadScene');  
    });
  }
}

export default TestScene;
