const map = [
    [13,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,99,15],
    [99,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,99],
    [11,52,0,0,0,0,0,0,0,0,0,98,0,0,0,0,98,0,0,0,0,98,0,0,0,0,0,0,98,98,98,98,98,98,0,0,0,10],
    [11,0,0,51,0,0,0,0,0,0,0,98,0,0,0,0,98,98,98,98,98,98,57,56,0,0,0,0,98,98,98,98,98,98,0,0,0,10],
    [11,0,0,0,0,0,0,0,0,0,0,98,98,98,98,98,98,0,0,0,0,0,0,98,98,98,98,98,98,98,98,98,98,98,0,0,0,10],
    [11,0,0,0,0,83,0,0,0,0,0,98,0,0,0,0,0,0,0,55,80,0,0,0,0,0,0,0,98,98,98,19,20,98,0,0,0,10],
    [11,0,0,0,0,0,0,0,0,0,0,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,98,98,98,98,98,98,0,0,0,10],
    [11,0,0,0,0,0,0,0,0,0,0,98,0,0,0,0,0,0,0,98,98,98,98,98,98,98,0,0,0,0,51,21,23,0,0,0,0,10],
    [11,98,98,98,98,98,98,98,98,98,98,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,51,51,0,0,0,0,0,10],
    [11,0,0,0,0,0,0,53,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [11,0,0,0,0,0,54,0,0,0,0,0,0,0,0,0,98,98,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,22],
    [11,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,98,30,98,16,0,0,0,0,0,16,0,0,16,0,0,0,0,0,0,0,17,99],
    [11,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,98,98,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,99],
    [11,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,50,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,18],
    [11,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [11,98,98,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [11,0,0,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [11,0,0,98,98,58,0,0,0,0,0,0,0,0,0,0,0,0,0,0,59,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [11,0,0,0,0,0,98,98,98,98,98,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,81,0,0,0,0,0,0,0,0,10],
    [11,0,0,0,0,0,0,0,0,0,0,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,82,0,0,0,0,0,10],
    [11,0,0,0,0,0,0,0,0,0,0,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10],
    [13,0,0,0,0,0,0,0,0,0,0,98,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,14],
    [99,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,31,99,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,99],
];

export default map;